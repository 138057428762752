<template>
  <div class="list-view checkout-items">
    <b-row>
      <b-col>
        <b-card
          v-for="product in cart.filter(item => item.tickets.length > 0)"
          :key="product.raffleId"
          class="ecommerce-card card-bg"
          no-body
        >
          <!-- Product Image -->
          <div class="item-img">
            <b-img
              :src="product.img"
              :alt="`${product.raffleName}-${product.raffleId}`"
              class="m-1"
              rounded="rounded"
            />
          </div>

          <!-- Product Details: Card Body -->
          <b-card-body>
            <div class="item-name">
              <h6 class="mb-0">
                <b-link class="text-body h2">
                  {{ product.raffleName }}
                </b-link>
              </h6>
            </div>
            <div class="item-quantity">
              <div class="d-flex flex-column">
                <span class="quantity-title">{{ totalPerRaffle(product.tickets) }} Ticket Coins</span>
                <span class="quantity-title">Tickets:</span>
                <div class="d-flex">
                  <b-button
                    v-for="(ticket, n) in product.tickets"
                    :key="`ticket-${n}`"
                    size="sm"
                    variant="gradient-primary"
                    class="mr-1 btn-cursor"
                    :disabled="loading"
                  >
                    {{ ticket.folio }}
                    <feather-icon
                      icon="XIcon"
                      class="cursor-pointer"
                      @click="release(product, ticket.id)"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        sm="12"
        md="6"
        lg="4"
      >
        <div class="checkout-options">
          <!-- Detalles de la compra 🛍️ -->
          <b-card class="sticky-top sticky-offset border-primary">
            <b-card-header class="p-0">
              <h4>
                Detalles de la compra
              </h4>
            </b-card-header>
            <div class="price-details mt-1">
              <hr>
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total">
                    Total
                  </div>
                  <div class="detail-amt font-weight-bolder">
                    {{ totalAmount }} pts
                  </div>
                </li>
              </ul>
              <b-row>
                <b-col>
                  <b-button
                    variant="primary"
                    block
                    :disabled="cart.length === 0"
                    @click="$emit('next-step')"
                  >
                    Continuar
                    <feather-icon icon="ChevronsRightIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex justify-content-center align-items-center mt-2">
              <cart-timer
                v-if="cart[0]"
                class="timer"
                :expires-time="cart[0].expiresAt"
              />
            </div>
          </b-card>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CartTimer from '@/@core/components/CartTimer.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    CartTimer,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters('tickets', ['cart']),
    ...mapGetters('auth', ['currentUser']),
    totalAmount() {
      let total = 0
      this.cart.forEach(raffle => {
        raffle.tickets.forEach(ticket => {
          total += ticket.price
        })
      })
      return total
    },
  },
  mounted() {
    this.getUserCart({ userId: this.currentUser.id })
      .then(this.checkCart())
  },
  methods: {
    ...mapActions('tickets', ['checkout', 'getUserCart']),
    ...mapActions('tickets', ['releaseTicket']),
    ...mapMutations('tickets', ['REMOVE_TICKET_FROM_CART']),
    checkCart() {
      if (Date.now() > this.cart[0].expiresAt) {
        this.getUserCart({ userId: this.currentUser.id })
      }
    },
    totalPerRaffle(currentRaffle) {
      let raffleTotal = 0
      currentRaffle.forEach(ticket => { raffleTotal += ticket.price })
      return raffleTotal
    },
    release(raffle, ticketId) {
      this.loading = true
      this.releaseTicket({
        roomId: raffle.raffleId,
        ticketId,
        userId: this.currentUser.id,
      })
      // eslint-disable-next-line no-return-assign
        .then(() => this.loading = false)
      this.getUserCart({ userId: this.currentUser.id })
      if (raffle.tickets.length === 1) {
        const itemIndex = this.cart.findIndex(
          p => p.raffleId === raffle.raffleId,
        )
        this.REMOVE_TICKET_FROM_CART(itemIndex)
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style lang="scss" scoped>
.card-bg {
  background-color: #f1f0ff;
}
.sticky-offset {
  top: 95px;
  z-index: 9;
}
.btn-cursor {
  cursor: default !important;
}
.text-small {
  font-size: 11px;
  li {
    margin: 0px;
  }
}
</style>
