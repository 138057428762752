<template>
  <div class="grid-container">
    <div>
      <home-menu bgwhite />
      <b-container class="pt-5">
        <h2 class="font-weight-bolder">
          Resumen de compra
        </h2>
        <product-checkout-card @next-step="completeSale()" />
      </b-container>
    </div>
    <main-footer class="d-none d-md-block" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mapActions } from 'vuex'
import HomeMenu from '@/views/home/HomeMenu.vue'
import ProductCheckoutCard from '@/@core/components/ProductCheckoutCard.vue'
import MainFooter from '@/@core/components/MainFooter.vue'

export default {
  components: {
    HomeMenu,
    ProductCheckoutCard,
    MainFooter,
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }
    const formWizardPrevStep = () => {
      refFormWizard.value.prevTab()
    }

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPrevStep,
    }
  },
  data() {
    return {
      menuHidden: this.$store.state.appConfig.layout.menu.hidden,
    }
  },
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },
  methods: {
    ...mapActions('tickets', ['checkout']),
    completeSale() {
      this.$bvModal.msgBoxConfirm('¿Estás segur@ de efecturar la compra?', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.checkout()
              .then(() => {
                this.$swal({
                  title: '¡Compra exitosa!',
                  text: 'Gracias.',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                this.$router.push({ name: 'home' })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh
}
</style>
